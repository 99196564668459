import React, { useState } from 'react';
import logo from '../images/logo.png';
import './component.css';

const asyncSaveEmailToDatabase = async (data) => {
  try{
      const response = await fetch("https://imkkbu8l5f.execute-api.us-east-2.amazonaws.com/dev");
      const id = await response.json();
  const createdDate = new Date().toUTCString();
  const {error, submitted, ...formattedData} = data;
  const request = {
    id,
    createdDate,
    ...formattedData
  }
  await fetch("https://wgnjlmvm5c.execute-api.us-east-2.amazonaws.com/dev",{
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(request)
  })
  return true;
  }

catch(error){
  console.error(error);
  return error;
}

};


function HeroSectionComponent() {
  const  regexEmail = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  const [form, setForm] = useState({
    email: "",
    submitted: false,
    error: ""
  });
  const setFieldValue = event => {
    setForm({
      ...form,
      [event.target.name]: event.target.value
    })
  }
  const saveEmailToDatabase = async(event) =>{
    event.preventDefault();
    const response = await asyncSaveEmailToDatabase(form);
    if(response === true){
      setForm({
        ...form,
        submitted: true
      })
    }else{
      setForm({
        ...form,
        error: response
      })
    }
  }
  const errors = Boolean(form.error) && (<span className="errors">There was an error please try again</span>)
  const showFormOrSubmitted = !form.submitted ?
  (<form className="signupForm">
    {errors}
  <label>Email<span>(required)</span></label>
  <input type="email" id="email" name="email" value={form.email} onChange={setFieldValue} required />
  <input type="submit" onClick={saveEmailToDatabase} disabled={!regexEmail.test(form.email)} value="Sign Me Up" />
</form>) :(<span className="section-blurb form-submission">Thank you for submitting!</span>)
  return (
    <div className="home-section-hero">
             <div className="hero-section-splach-background"><img src={logo} alt="logo" className="header-logo-image" />
      <span className="section-title">Coming soon!</span>
      <span className="section-blurb">Add your email to the mailing list to get the latest updates.</span>
      {showFormOrSubmitted}

      </div> 
    </div>
  );
}

export default HeroSectionComponent;
