import React from 'react';
import logo from '../images/logo.png';
import './component.css';
import { Link, animateScroll as scroll } from "react-scroll";
const scrollToTop = () => {
  scroll.scrollToTop();
};
function HeaderComponent() {

  return (
      <header className="header-component">
        <div className="header-logo-container">
        <img src={logo} alt="logo" className="header-logo-image" onClick={scrollToTop} />
        </div>
<nav className="header-nav-container">
  <ul className="header-nav-menu">
   {/*      <li className="header-nav-menu-item">
      <Link
      activeClass="active"
      to="about-section"
      spy={true}
      smooth={true}
      duration={500}>
        About
      </Link>
    </li>
    <li className="header-nav-menu-item">
      <Link
      activeClass="active"
      to="services-section"
      spy={true}
      smooth={true}
      duration={500}>
        Services
      </Link></li>
    <Link
      activeClass="active"
      to="faq-section"
      spy={true}
      smooth={true}
      duration={500}>
        FAQs
      </Link></li>
    <Link
      activeClass="active"
      to="services-section"
      spy={true}
      smooth={true}
      duration={500}>
        Pricing
      </Link></li> 
    <li className="header-nav-menu-item">
      <Link
      activeClass="active"
      to="contact-section"
      spy={true}
      smooth={true}
      duration={500}>
        Contact
      </Link></li>*/}

  </ul>
</nav>
      </header>
  );
}

export default HeaderComponent;
